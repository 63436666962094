.aboutPage {
    
    .mainSection {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        gap: var(--big-gap);

        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
        }

        & h1 {
            padding-bottom: var(--big-gap);
        }

        .image {
            width: 100%;
        }
    }

    .teamSection {
        
        .teamMembersContainer {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-content: center;
            gap: var(--big-gap);

            @media only screen and (max-width: 768px) {
                grid-template-columns: 1fr;
            }
            
            .teamMemberContainer {
                border: var(--border-2);
                padding: var(--big-gap);
                display: flex;
                flex-direction: column;
                gap: var(--small-gap);
                
                .image {
                    max-width: 100%;
                    padding-bottom: var(--big-gap);
                }

                & h5 {
                    text-align: left;
                }
            }
        }
            
    }

    .additionalSection {
        display: flex;
        flex-direction: column;
        gap: var(--big-gap);

        & p {
            max-width: 700px;
        }

        .image {
            width: 100%;
        }
    }
}