.contactPage {
    
    .contactSection {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--big-gap);
        align-items: center;
        padding: 0;
        min-height: calc(100vh - var(--header-height));

        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            gap: var(--medium-gap);
        }

        .contactFormContainer {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            background-color: var(--yellow-2);
            padding-left: var(--side-padding-for-sections);
            padding-top: var(--big-gap);
            padding-bottom: var(--big-gap);
            padding-right: var(--big-gap);
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            & h2 {
                color: white;
            }


        }

        .otherContactOptionsContainer {
            display: flex;
            gap: var(--big-gap);
            flex-direction: column;
            align-items: start;
            
            @media only screen and (max-width: 768px) { 
                grid-column: 1 / 2;
                grid-row: 2 / 3;
                padding: var(--big-gap) var(--side-padding-for-sections);
                gap: var(--medium-gap);

            }
            
            .contactsContainer {
                grid-column: 2 / 3;
                grid-row: 1 / 2;

                .contactContainer {
                    display: flex;
                    align-items: center;
                    gap: var(--small-gap);
                    
                    & a {
                        display: flex;
                        align-items: center;
                        gap: var(--small-gap);
                    }
        
                    .icon {
                        width: 24px;
                        max-height: 24px;
                    }
                }
            }

            & h5 {
                padding-top: var(--medium-gap);
            }

            .socialLinksContainer {
                grid-column: 1 / 3;
                grid-row: 3 / 4;
                display: flex;
                justify-content: center;
                gap: var(--small-gap)
            }
        }
    }    
}