.productsPage {
    background-color: var(--yellow-1);

    .productsContainer {
        display: flex;
        flex-wrap: wrap;
        gap: var(--big-gap);
        justify-content: start;

        @media only screen and (max-width: 768px) {
            justify-content: center;
        }
        
        .productContainer {
            border: 1px solid white;
            padding: var(--big-gap);
            text-decoration: none;
            /* width: min-content; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: var(--medium-gap);
            max-width: 350px;
            
            .image {
                object-fit: cover;
                flex-grow: 1;
            }
            
            .description {
                text-align: center;
            }
        }
    }
}