:root {
    --header-height: 10vh;
    --max-content-width: 1200px;
    --side-padding-for-sections: max(5vw, calc((100vw - var(--max-content-width)) / 2));
    --very-big-gap: 64px;
    --big-gap: 32px;
    --medium-gap: 16px;
    --small-gap: 8px;

    --h1-font-size: 53px;
    --h2-font-size: 46px;
    --h3-font-size: 38px;
    --h5-font-size: 24px;
    --h6-font-size: 18px;
    --body-font-size: 18px;

    --transition: 0.3s;
    --border: 0.5px solid black;
    --border-2: 2px solid var(--yellow-2);
    --border-radius: 2px;
    --border-white: 1px solid white;
    --border-black: 1px solid var(--normal-text);

    --green-1: #648149;
    --green-2: #4E6C31;
    --grey-1: #B1B5B4;
    --yellow-1: #BFA15A;
    --yellow-2: #BF925A;
    --beige-1: #CDAA7F;
    --normal-text: #262626;

    --button-height: 45px;

    @media only screen and (max-width: 768px) {
        --h1-font-size: 30px;
        --h2-font-size: 26px;
        --h3-font-size: 23px;
        --h5-font-size: 20px;
    }
}

@font-face {
    font-family: 'font-1';
    src: 
        url('../resources/fonts/Cinzel-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: "font-2";
    src: url("../resources/fonts/Rubik-VariableFont_wght.ttf") format("truetype");
}
