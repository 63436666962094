.homePage {
    
    .heroSection {
        height: calc(100vh - var(--header-height));
        background-image: url("../resources/kalahari-oil-on-background-for-hero.jpg");
        background-position: center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: var(--big-gap);
        padding-bottom: var(--big-gap);

        .headerAndHeadlineContainer {
            padding-bottom: var(--small-gap);
        }
    
        & h1, p {
            color: white;
        }
    
        .square {
            width: min(800px, 100%);
            height: 450px;
            border: 3px solid white;
            display: flex;
            align-items: end;
            justify-content: center;
            padding: var(--big-gap);

    
            .text {
                text-align: center;
            }
        }
    
        & button {
            position: relative;
            top: -24px;
        }
    }
    
    .welcomeSection {

        .contentContainer {
            display: grid;
            grid-template-columns: 1fr var(--big-gap) 1fr var(--big-gap);
            grid-template-rows: repeat(4, min-content);
            column-gap: calc(var(--big-gap) * 2);
            row-gap: var(--big-gap);
            align-items: center;

            @media only screen and (max-width: 768px) {
                grid-template-columns: 1fr 1fr;
                gap: var(--medium-gap);

            }
    
            .text1 {
                grid-column: 1 / 5;
                grid-row: 1 / 2;
                width: min(650px, 100%);
                justify-self: center;
                text-align: center;
                
                @media only screen and (max-width: 768px) {
                    text-align: left;
                    grid-column: 1 / 3;
                    
                }
            }
    
            .womenWithBasketsImage {
                grid-column: 1 / 2;
                grid-row: 2 / 4;
                width: 100%;


                @media only screen and (max-width: 768px) {
                    grid-column: 1 / 2;
                    
                }
            }
            
            .womanHoldingNutsImage {
                grid-column: 2 / 4;
                grid-row: 2 / 3;
                width: 100%;
                
                @media only screen and (max-width: 768px) {
                    height: 100%;
                    grid-column: 2 / 3;
                    object-fit: cover;
                }
            }
            
            .text2 {
                grid-column: 1 / 3;
                grid-row: 4 / 5;
            }
            
            .basketWithSeedsImage {
                grid-column: 3 / 5;
                grid-row: 3 / 5;
                width: 100%;

                @media only screen and (max-width: 768px) {
                    grid-column: 2 / 3;
                    grid-row: 3 / 4;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    
    .whyUseSection {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 300px;
        gap: var(--big-gap);
        align-items: center;
        background-image: url("../resources/backgound-1.jpg");
        background-size: cover;
        background-position: center;

        @media only screen and (max-width: 768px) {
            grid-template-columns: 2fr 1fr;
            grid-template-rows: min-content min-content;
            gap: var(--medium-gap);
        }

        .textContainer {
            @media only screen and (max-width: 768px) {
                grid-column: 1 / 3;
            }
        }
    
        .smilingWomanImageContainer {
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;

            @media only screen and (max-width: 768px) {
                grid-row: 2 / 3;
            }
    
            .image.smilingWoman {
                height: 100%;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                    height: auto;
                }
            }
        }
        
        .image.oils {
            grid-column: 2 / 3;
            justify-self: end;
            height: 100%;

            @media only screen and (max-width: 768px) {
                align-self: end;
                grid-row: 2 / 3;
                width: 100%;
                height: auto;
            }

        }
    }

    .productsSection {
        background-color: var(--yellow-2);
    }

    .reviewsSection {
        display: grid;
        grid-template-columns: calc((100% - var(--side-padding-for-sections))/2) 20% 1fr var(--side-padding-for-sections);
        grid-template-rows: min-content 1fr;
        padding-right: 0;
        align-items: center;

        @media only screen and (max-width: 768px) {
            display: block;
            padding-right: var(--side-padding-for-sections);
        }

        & h2 {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }

        .contentContainer {
            grid-column: 1 / 3;
            grid-row: 2 / 3;
        }

        .imageContainer {
            grid-column: 2 / 5;
            grid-row: 1 / 3;

            @media only screen and (max-width: 768px) {
                display: none;
            }

            .image {
                max-width: 100%;
            }
        }
    }

    .feature1Section {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: var(--big-gap);
        
        @media only screen and (max-width: 768px) {
            gap: var(--medium-gap);
            grid-template-rows: 1fr min-content;
        }

        .villagersWithMonicaImage1 {
            grid-column: 1 / 2;

            @media only screen and (max-width: 768px) {
                grid-column: 1 / 3;
                grid-row: 2 / 3;
                width: 100%;
            }

        }

        .contentContainer {
            grid-column: 2 / 3;
            display: flex;
            flex-wrap: wrap;
            gap: var(--medium-gap);
            
            @media only screen and (max-width: 768px) {
                grid-column: 1 / 3;
                grid-row: 1 / 2;

                & h2 {
                    width: 100%;
                    text-align: center;

                }
            }

            .villagersWithMonicaImage2 {
                align-self: end;
                padding-top: var(--big-gap);
                
                @media only screen and (max-width: 768px) {
                    padding-top: none;
                    width: 100%;
                }
            }
        }
    }

    .feature2Section {
        background-image: url("../resources/feature-2-background.jpg");
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
        grid-template-rows: min-content min-content var(--big-gap) max-content;
        gap: var(--medium-gap);
        
        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(5, min-content);
            gap: var(--medium-gap);
        }

        .image {
            width: 100%;
        }

        .textContentContainer {
            grid-column: 1 / 4;
            grid-row: 1 / 2;

            @media only screen and (max-width: 768px) {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
            }
        }

        .textContentContainer2 {
            grid-column: 1 / 4;
            grid-row: 2 / 3;

            @media only screen and (max-width: 768px) {
                grid-column: 1 / 2;
                grid-row: 3 / 4;
            }
        }

        .image.berries2 {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            @media only screen and (max-width: 768px) {
                grid-column: 1 / 2;
                grid-row: 4 / 5;
            }
        }

        .image.berries3 {
            grid-column: 4 / 6;
            grid-row: 1 / 3;

            @media only screen and (max-width: 768px) {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
            }
        }

        .image.berries1 {
            grid-column: 3 / 5;
            grid-row: 4 / 5;

            @media only screen and (max-width: 768px) {
                grid-column: 1 / 2;
                grid-row: 5 / 6;
            }
        }
    }

    .feature3Section {
        background-image: url("../resources/giraffe-background.jpg");
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: start;
        min-height: min(calc(100vh - var(--header-height)), 800px);

        @media only screen and (max-width: 768px) {
            background-position: 30% 100%;
        }

        .textContainer {
            padding: var(--very-big-gap);
            background-color: white;
            max-width: min(900px, 80vw);

            @media only screen and (max-width: 768px) {
                padding: var(--medium-gap);
                text-align: center;
            }
        }
    }

    .feature4Section {
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: min-content 1fr max-content;
        gap: var(--big-gap);

        @media only screen and (max-width: 768px) {
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 1fr min-content;
            gap: var(--medium-gap);
        }

        .textContainer {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            
            @media only screen and (max-width: 768px) {
                grid-column: 1 / 3;
            }
        }

        .image.woman1 {
            grid-column: 1 / 2;
            grid-row: 2 / 4;

            @media only screen and (max-width: 768px) {
                width: 100%;
                grid-column: 1 / 2;
                grid-row: 2 / 3;
                align-self: end;
            }
        }

        .image.woman2 {
            grid-column: 2 / 3;
            grid-row: 1 / 3;

            @media only screen and (max-width: 768px) {
                width: 100%;
                grid-column: 2 / 3;
                grid-row: 2 / 3;
            }
        }
    }

    .certificatesSection {
        background-image: url("../resources/backgound-1.jpg");
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 70vh;

        .certificatesContainer {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: var(--big-gap);
            width: min(700px, 70%);

            @media only screen and (max-width: 768px) {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: var(--medium-gap);
            }

            .image.certificate {
                width: 100%;
            }
        }
    }

    .contactSection {
        background-color: var(--yellow-2);
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0;

        @media only screen and (max-width: 768px) {
            grid-template-columns: 1fr;
            grid-template-rows: 2fr 1fr;
        }

        .contactContainer {
            padding-left: var(--side-padding-for-sections);
            padding-top: var(--big-gap);
            padding-bottom: var(--very-big-gap);
            padding-right: var(--big-gap);
            display: flex;
            flex-direction: column;
            justify-content: center;


            @media only screen and (max-width: 768px) {
                grid-column: 1 / 2;
            }

            & h2 {
                color: white;
            }
        }

        .imageContainer {
            background-image: url("../resources/mongongo-oil-in-flask.jpg");
            background-position: center;
            background-size: cover;

            @media only screen and (max-width: 768px) {
                grid-column: 1 / 2;
                background-position: 0%;
            }

        }
    }
}

.middleLine {
    width: 1px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 50%;
    background-color: red;
    /* z-index: 5; */
}