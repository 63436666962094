.productPage {

    .mainInfoSection {
        display: grid;
        gap: var(--big-gap);
        grid-template-columns: 1fr 1fr;
        grid-template-rows: min-content min-content 1fr;

        .breadcrumbs {
            grid-column: 1 / 3;
            grid-row: 1 / 2;
        }

        .name {
            grid-column: 1 / 3;
            grid-row: 2 / 3;
        }

        .photoContainer {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr min-content;
            gap: var(--big-gap);
            
            @media only screen and (max-width: 768px) {
                grid-column: 1 / 3;
                gap: var(--small-gap);
            }

            .mainImageAndArrowsContainer {
                grid-column: 1 / 2;
                grid-row: 1 / 2;
                position: relative;
                width: 100%;
                height: 100%;

                .image.main {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    
                    @media only screen and (max-width: 768px) {
                        /* height: auto; */

                    }
                }

                .leftArrow {
                    position: absolute;
                    left: var(--medium-gap);
                    top: 50%;
                    transform: translateY(-50%);
                    width: var(--very-big-gap);
                }
                
                .rightArrow {
                    position: absolute;
                    right: var(--medium-gap);
                    top: 50%;
                    transform: translateY(-50%);
                    width: var(--very-big-gap);
                }
            }


            .thumbnailsContainer {
                grid-column: 1 / 2;
                grid-row: 2 / 3;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                justify-content: space-between;
                gap: var(--medium-gap);

                .image {
                    width: 100%;
                    height: 100%;
                    object-position: center;
                    object-fit: cover;
                }
            }

        }

        .textContainer {
            grid-column: 2 / 3;
            grid-row: 3 / 4;
            border: var(--border-2);
            padding: var(--big-gap);
            position: relative;

            @media only screen and (max-width: 768px) {
                grid-column: 1 / 3;
                grid-row: 4 / 5;
            }

            & h5 {
                color: var(--yellow-2);
                text-align: left;
                padding-bottom: var(--small-gap);
            }

            .propertiesList {
                list-style: none;

                .propertyIcon {
                    padding-right: var(--small-gap);
                }
            }

            & p, .propertiesList {
                padding-bottom: var(--big-gap);
            }

            .button {
                position: absolute;
                left: 50%;
                bottom: calc(var(--button-height) / 2 * (-1));
                transform: translateX(-50%);
            }
        }
    }

    .additionalInfoSection {
        display: flex;
        flex-direction: column;
        gap: var(--very-big-gap);
        
        .additionalInfoContainer {
            display: grid;
            grid-template-columns: 1fr 400px;
            gap: var(--very-big-gap);

            @media only screen and (max-width: 768px) {
                grid-template-columns: 1fr;
                grid-template-rows: min-content min-content;
                gap: var(--medium-gap);
            }

            .textContainer {
                grid-column: 1 / 2;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media only screen and (max-width: 768px) {
                    grid-row: 1 / 2;
                }

                & h3 {
                    padding-bottom: var(--medium-gap);
                }
            }

            .image {
                grid-column: 2 / 3;
                width: 100%;

                @media only screen and (max-width: 768px) {
                    grid-column: 1 / 2;
                    grid-row: 2 / 3;
                }

            }

        }
    }
}