.contactForm {
    width: min(600px, 100%);
    position: relative;
    border: var(--border-white);

    .fieldsContainer {
        display: flex;
        flex-direction: column;
        gap: var(--big-gap);
        transition: var(--transition);
        padding: var(--big-gap) var(--very-big-gap);
        
        @media only screen and (max-width: 768px) {
            padding: var(--big-gap);
        }
        
        .labelAndInputContainer {
            border-bottom: 1px solid var(--beige-1);
            display: flex;
            gap: var(--medium-gap);

            & label {
                color: white;
                width: 70px;
            }
            
            & input {
                color: white;
                outline: none;
                border: none;
                background: none;
                width: 100%;
            }
        }

        &.hidden {
            visibility: hidden;
            opacity: 0;
        }
    }

    .captchaContainer {
        display: flex;
        justify-content: center;
    }

    .button {
        position: relative;
        top: calc(var(--button-height) / 2 * (+1));
        left: 50%;
        transform: translateX(-50%);

        &.hidden {
            visibility: hidden;
            opacity: 0;
        }
    }

    .statusText {
        position: absolute;
        top: 50%;
        color: white;
        text-align: center;
        width: 100%;
        transition: var(--transition);
        font-style: italic;

        &.hidden {
            visibility: hidden;
            opacity: 0;
        }
    }
}

