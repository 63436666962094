footer {
    border-top: var(--border);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content min-content min-content;
    justify-items: center;
    align-items: center;
    row-gap: var(--small-gap);
    padding-bottom: var(--medium-gap);
    padding-top: var(--medium-gap);

    @media only screen and (max-width: 768px) {
        gap: var(--big-gap);
    }
    
    .logoContainer {
        width: min(300px, 50%);
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        justify-self: start;
        display: flex;
        align-items: center;

        .logo {
            width: 100%;
        }

        @media only screen and (max-width: 768px) {
            grid-column: 1 / 3;
        }
    }

    .contactsContainer {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        
        @media only screen and (max-width: 768px) {
            padding-bottom: var(--medium-gap);
            border-bottom: var(--border);
            grid-column: 1 / 3;   
            grid-row: 2 / 3;
        }

        .contactContainer {
            display: flex;
            align-items: center;
            gap: var(--small-gap);
            
            & a {
                display: flex;
                align-items: center;
                gap: var(--small-gap);
            }

            .icon {
                width: 24px;
                max-height: 24px;
            }
        }
    }
    
    .menuContainer {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        display: flex;
        justify-content: center;
        gap: var(--big-gap);
        
        @media only screen and (max-width: 768px) {
            justify-self: start;
            grid-column: 1 / 2;
            grid-row: 3 / 4;   
            flex-direction: column;
            gap: var(--small-gap);
        }
    }
    
    .socialLinksContainer {
        grid-column: 1 / 3;
        grid-row: 3 / 4;
        display: flex;
        justify-content: center;
        gap: var(--small-gap);
        
        @media only screen and (max-width: 768px) {
            justify-self: end;
            grid-column: 2 / 3;
            grid-row: 3 / 4;
            flex-direction: column;
        }
    }
    
    .copyright {
        grid-column: 1 / 3;
        grid-row: 4 / 5;
        color: var(--grey-1);
    }
}