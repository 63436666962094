.productCategoriesContainer {
    display: flex;
    justify-content: center;
    gap: var(--very-big-gap);
    flex-wrap: wrap;

    .productContainer {
        border: 1px solid white;
        padding: 50px 50px calc(50px + var(--button-height) / 2) 50px;
        position: relative;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        
        @media only screen and (max-width: 768px) {
            padding: var(--medium-gap) var(--medium-gap) calc(var(--medium-gap) + var(--button-height) / 2) var(--medium-gap);
        }

        .image {
            width: min(400px, 100%);
            height: min(320px, auto);
        }

        .name {
            padding-top: var(--medium-gap);
            padding-bottom: var(--medium-gap);
            background-color: white;
        }

        .button {
            position: absolute;
            bottom: calc(var(--button-height) / 2 * (-1) );
            left: 50%;
            transform: translateX(-50%);
        }
    }
}