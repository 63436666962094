header {
    padding-bottom: 0.5px;
    position: sticky;
    top: 0;
    width: 100%;
    height: var(--header-height);
    background-color: white;
    border-bottom: var(--border);
    z-index: 1;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 100%;
    grid-auto-flow: column;
    align-items: center;
    /* overflow-x: clip; */

    
    @media only screen and (max-width: 768px) {
        grid-template-columns: 2fr 1fr 1fr;
    }

    .logoContainer {
        height: 100%;
        width: fit-content;

        .logo {
            height: 100%;
        }
    }
    
    .menuContainer {
        display: flex;
        justify-content: center;
        gap: var(--big-gap);

        .closeButtonForMobile {
            display: none;
        }

        @media only screen and (max-width: 768px) {
            /* visibility: hidden; */
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            height: 100vh;
            padding: var(--big-gap);
            padding-top: calc(var(--header-height) + var(--big-gap));
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(50vw);
            background-color: var(--yellow-2);
            transition: var(--transition);
            z-index: 10;
            /* overflow-x: clip; */
            
            &.revealed {
                visibility: visible;
                transform: translateX(0);
            }

            & * {
                font-size: 22px;
            }

            .closeButtonForMobile {
                display: block;
                position: absolute;
                top: calc(var(--big-gap));
                right: var(--big-gap);
            }
        }
    }

    .backdropForMobile {
        display: none;
    }

    @media only screen and (max-width: 768px) {
        .backdropForMobile.revealed{
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background-color: black;
            opacity: 0.5;
            z-index: 3;
        }
    }

    .whatsAppButtonContainer {
        justify-self: end;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .burgerMenu {
        justify-self: end;
        display: none;
        height: var(--big-gap);
        width: calc(var(--big-gap) * 1.5);
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
            display: flex;
        }

        .burgerLine {
            width: 100%;
            height: 3px;
            background-color: var(--normal-text);
            border-radius: var(--border-radius);
        }
    }
}


@media only screen and (max-width: 768px) {
    body.mobileMenuRevealed {
        overflow: clip;
    }
}