*, *::after, *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    overflow-x: clip;
}

section {
    overflow-x: clip;
}

header, section, footer {
    padding-left: var(--side-padding-for-sections);
    padding-right: var(--side-padding-for-sections);
}

section {
    padding-top: var(--very-big-gap);
    padding-bottom: var(--very-big-gap);
}

.image {
    border-radius: var(--border-radius);
}

/* TYPOGRAPHY */

h1, h2, h3, h5, h6, p, span, a, div, ul {
    color: var(--normal-text);
    line-height: 1.5;
}

h1 {
    font-size: var(--h1-font-size);
    text-align: center;
    font-family: "font-1";
    font-weight: 400;
}

h2 {
    font-size: var(--h2-font-size);
    text-align: center;
    font-family: "font-1";
    font-weight: 400;
    padding-bottom: var(--big-gap);
}

h3 {
    font-family: "font-1";
    font-size: var(--h3-font-size);
    font-weight: 400;
    line-height: 120%; 
}

h5 {
    font-family: "font-1";
    font-size: var(--h5-font-size);
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
}

h6 {
    font-size: var(--h6-font-size);
    font-weight: 500;
    display: inline;
    font-family: "font-2";
}

p, a, span, div, label, input {
    font-size: var(--body-font-size);
    font-family: "font-2";
    font-weight: 300;
}

a {
    text-decoration: none;
    color: var(--normal-text);
    cursor: pointer;
}

/* BUTTON */

button {
    outline: 0;
    border: 0;
    height: var(--button-height);
    width: max-content;
    padding: 8px 25px;
    background-color: white;
    transition: var(--transition);
    font-weight: 400;
    font-size: 18px;
    text-decoration: none;
    color: var(--normal-text);

    &.yellow {
        background-color: var(--yellow-2);
        color: white;
    }

    &:hover {
        background-color: var(--green-1);
        color: white;
        cursor: pointer;
    }
    
    &:active {
        background-color: var(--green-2);
        color: white;
    }
    
    &:disabled {
        background-color: var(--grey-1);
        color: white;
    }
}

/* PAGE TRANSITION ANIMATION (WHITE BOX) */

.whiteBoxContainer {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: end;
    justify-content: center;

    .whiteBox {
        position: relative;
        z-index: 50;
        width: 100%;
        background-color: rgb(255, 255, 255);
    }
}

