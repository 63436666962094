.sliderContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    z-index: 2;

    .reviewsContainer {
        position: relative;
        display: grid;
        grid-template-rows: 1fr;
        grid-auto-flow: column;
        transition: var(--transition);

            .reviewContainer {
                padding: var(--medium-gap);
                background-color: white;
                animation-duration: 1s;

                .recommendedText {
                    font-size: 22px;
                    font-weight: 500;
                    padding-bottom: var(--medium-gap);
                }

                .starsContainer {
                    display: flex;
                    gap: 4px;
                    padding-bottom: var(--medium-gap);
                }

                .reviewText {
                    padding-bottom: var(--medium-gap);
                }

                .reviewAuthor {
                    display: block;
                    text-align: end;
                }

                &.hidden {
                    visibility: hidden;
                }
            }
        }
        
    .paginationContainer {
        display: flex;
        align-items: center;
        gap: var(--medium-gap);
        width: 100%;
        padding-top: var(--big-gap);
        
        @media only screen and (max-width: 768px) {
            padding-top: var(--medium-gap);
            justify-content: center;
        }
        
        .paginationText {
            font-size: 36px;
            font-family: "font-1";
            color: var(--green-1);
        }
    }
}
